<app-breadcrums [seccion]="seccionActual"></app-breadcrums>

<div class="wrapper">
  <svg>
    <text x="50%" y="50%" dy=".35em" text-anchor="middle" class="text-[54px] sm:text-[100px] xl:text-[150px]">
      BIENVENIDO
    </text>
  </svg>
</div>

<div class="my-6 justify-center">
  <p class="ic-label-encabezado text-center">Citas de hoy</p>

  <ic-table-data
    placeholder="Buscar"
    [setDataTable]="lRegCitas"
    [tableColumns]="displayedColumns"
    [isFilterable]="false"
    [isPageable]="false"
    [defaultPageSize]="50"
    color_td="active_color"
    (goToDetails)="goToDetails($event)"
    [searchManual]="true">
  </ic-table-data>
</div>

<div class="section my-8">
  <p class="ic-label-encabezado text-center">Acciones rápidas</p>

  <div class="mx-4 my-4 grid grid-cols-1 gap-8 sm:grid-cols-3">
    <button type="button" class="ic-btn-primary" [routerLink]="['/prospectos/agregar']">
      <span> Agregar prospecto </span>
    </button>

    <button type="button" class="ic-btn-primary" [routerLink]="['/publicaciones-v2']">
      <span> Ver autos publicados </span>
    </button>

    <button type="button" class="ic-btn-primary" [routerLink]="['/autos/por-publicar']">
      <span> Ver autos por publicar </span>
    </button>
  </div>
</div>
